import React from "react";
import { WhatsappImage } from "../../components/WhatsappImage";
import "./style.css";

export const MacbookPro = () => {
  return (
    <div className="macbook-pro">
      <div className="div">
        <div className="group">
          <img className="frame" alt="Frame" src="/img/frame-1000004379.svg" />
          <img className="slice" alt="Slice" src="/img/slice-1-2.png" />
        </div>
        <div className="overlap">
          <div className="rectangle" />
          <img className="game-icons-comb" alt="Game icons comb" src="/img/game-icons-comb.svg" />
          <div className="overlap-wrapper">
            <div className="overlap-group">
              <div className="group-2">
                <img className="vector" alt="Vector" src="/img/vector.svg" />
                <img className="img" alt="Game icons comb" src="/img/game-icons-comb-1.svg" />
                <img className="tabler-razor" alt="Tabler razor" src="/img/tabler-razor.svg" />
                <div className="overlap-2">
                  <img className="tabler-razor-2" alt="Tabler razor" src="/img/tabler-razor-1.svg" />
                  <img className="mdi-content-cut" alt="Mdi content cut" src="/img/mdi-content-cut-1.svg" />
                </div>
                <img className="mdi-content-cut-2" alt="Mdi content cut" src="/img/mdi-content-cut.svg" />
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <img
                      className="whatsapp-image-2"
                      alt="Whatsapp image"
                      src="/img/whatsapp-image-2024-07-24-at-13-57-46-10fd64f5-2.png"
                    />
                    <img className="image" alt="Image" src="/img/image-11.png" />
                  </div>
                </div>
              </div>
              <div className="group-3">
                <WhatsappImage className="whatsapp-image-2024-07-24-at-13-57-47-5a68e93c-2" property1="default" />
                <p className="text-wrapper">Subscribe to our mailing list to get latest updates</p>
                <p className="hello-we-re">
                  Hello! we’re currently building our new fashion store.
                  <br /> Add your details to stay updated on our lunch date
                </p>
                <div className="notify-me">
                  <div className="overlap-3">
                    <div className="div-wrapper">
                      <div className="overlap-group-3">
                        <p className="p">Please insert yor email address</p>
                      </div>
                    </div>
                    <div className="group-4">
                      <div className="overlap-4">
                        <div className="text-wrapper-2">NOTIFY ME</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
