import React from "react";
import "./style.css";

export const MacbookProScreen = () => {
  return (
    <div className="macbook-pro-screen">
      <div className="group-5">
        <div className="group-wrapper">
          <div className="group-6">
            <div className="text-wrapper-3">Our Product</div>
            <div className="frame-2">
              <img className="westlife-saloon" alt="Westlife saloon" src="/img/westlife-saloon.png" />
              <div className="westlife-saloon-2">
                <div className="overlap-5">
                  <div className="rectangle-2" />
                  <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-226-1.png" />
                  <div className="BOOK-NOW-wrapper">
                    <div className="BOOK-NOW">
                      <div className="overlap-group-4">
                        <div className="text-wrapper-4">ADD TO CARTS</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper-5">Hair Shampoo Spray Prose</div>
                  <div className="text-wrapper-6">$12.50 -43.20</div>
                </div>
              </div>
              <div className="westlife-saloon-3">
                <div className="overlap-5">
                  <div className="rectangle-2" />
                  <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-226.png" />
                  <div className="BOOK-NOW-wrapper">
                    <div className="BOOK-NOW">
                      <div className="overlap-group-4">
                        <div className="text-wrapper-4">ADD TO CART</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper-5">Hair Shampoo Spray Prose</div>
                  <div className="text-wrapper-6">$12.50 -43.20</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-section">
        <div className="group-7">
          <img className="heading" alt="Heading" src="/img/heading.svg" />
          <div className="heading-2">About us</div>
          <p className="heading-3">Neque porro quisquam est qui dolum</p>
          <p className="untitled-is-growing">
            “ It is a long established fact that a reader will be tracked distracted by the readable content of a page
            is when looking at its layout. The point of using Lorem of distribution it look like readable English “
          </p>
          <img className="heading-4" alt="Heading" src="/img/heading-1.svg" />
        </div>
        <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-4555.png" />
      </div>
      <div className="group-8">
        <div className="text-wrapper-7">TEESTIMONIALS</div>
        <div className="group-9">
          <div className="reviews">
            <div className="overlap-group-5">
              <div className="group-10">
                <p className="i-must-say-this-has">
                  I must say, this has been one of the best website i’ve tried. They provide the best product <br />
                  and barber.
                </p>
                <img className="ellipse" alt="Ellipse" src="/img/ellipse-171.png" />
                <div className="text-wrapper-8">Macky Jane</div>
                <div className="text-wrapper-9">Makeup Artist</div>
              </div>
            </div>
            <div className="img-wrapper">
              <img className="group-11" alt="Group" src="/img/group-5.png" />
            </div>
          </div>
          <div className="REVIEW-wrapper">
            <div className="REVIEW">
              <p className="i-must-say-this-has">
                I must say, this has been one of the best website i’ve tried. They provide the best product <br />
                and barber.
              </p>
              <img className="ellipse" alt="Ellipse" src="/img/ellipse-171-1.png" />
              <div className="text-wrapper-8">Macky Jane</div>
              <div className="text-wrapper-9">Makeup Artist</div>
            </div>
          </div>
          <div className="group-12">
            <img className="group-13" alt="Group" src="/img/group-5-1.png" />
          </div>
        </div>
      </div>
      <div className="group-14">
        <div className="group-15">
          <div className="overlap-group-6">
            <div className="frame-3">
              <div className="card">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-11.png" />
                <div className="column">
                  <div className="frame-4">
                    <div className="frame-5">
                      <img className="frame-6" alt="Frame" src="/img/frame.svg" />
                      <div className="text-wrapper-10">4.5</div>
                    </div>
                    <div className="heading-5">104 reviews</div>
                  </div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">James Paul</div>
                    <div className="frame-8">
                      <img className="placeholder" alt="Placeholder" src="/img/placeholder-1-1.svg" />
                      <p className="text-wrapper-12">Brookpark Ext, 27085, North Olmsted, 44070</p>
                    </div>
                  </div>
                  <button className="button">
                    <div className="lorem-ipsum">BOOK NOW</div>
                  </button>
                </div>
              </div>
              <div className="card-2">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-11-1.png" />
                <div className="column">
                  <div className="frame-4">
                    <div className="frame-5">
                      <img className="frame-6" alt="Frame" src="/img/frame-1.svg" />
                      <div className="text-wrapper-10">4.5</div>
                    </div>
                    <div className="heading-5">104 reviews</div>
                  </div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">James Paul</div>
                    <div className="frame-8">
                      <img className="placeholder" alt="Placeholder" src="/img/placeholder-1-2.svg" />
                      <p className="text-wrapper-12">Brookpark Ext, 27085, North Olmsted, 44070</p>
                    </div>
                  </div>
                  <button className="button">
                    <div className="lorem-ipsum">BOOK NOW</div>
                  </button>
                </div>
              </div>
              <div className="card-2">
                <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-11-2.png" />
                <div className="column">
                  <div className="frame-4">
                    <div className="frame-5">
                      <img className="frame-6" alt="Frame" src="/img/frame-2.svg" />
                      <div className="text-wrapper-10">4.5</div>
                    </div>
                    <div className="heading-5">104 reviews</div>
                  </div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">Roger Smith</div>
                    <div className="frame-8">
                      <img className="placeholder" alt="Placeholder" src="/img/placeholder-1-3.svg" />
                      <p className="text-wrapper-12">Brookpark Ext, 27085, North Olmsted, 44070</p>
                    </div>
                  </div>
                  <button className="button">
                    <div className="lorem-ipsum">BOOK NOW</div>
                  </button>
                </div>
              </div>
              <div className="card-2">
                <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-11-3.png" />
                <div className="column">
                  <div className="frame-4">
                    <div className="frame-5">
                      <img className="frame-6" alt="Frame" src="/img/frame-3.svg" />
                      <div className="text-wrapper-10">4.5</div>
                    </div>
                    <div className="heading-5">104 reviews</div>
                  </div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">Roger Smith</div>
                    <div className="frame-8">
                      <img className="placeholder" alt="Placeholder" src="/img/placeholder-1.svg" />
                      <p className="text-wrapper-12">Brookpark Ext, 27085, North Olmsted, 44070</p>
                    </div>
                  </div>
                  <button className="button">
                    <div className="lorem-ipsum">BOOK NOW</div>
                  </button>
                </div>
              </div>
            </div>
            <img className="frame-9" alt="Frame" src="/img/frame-1000004349.svg" />
          </div>
          <div className="our-recommended">
            Our Recommended
            <br /> Barbers
          </div>
        </div>
      </div>
      <div className="group-16">
        <div className="frame-10">
          <div className="frame-11">
            <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-238.png" />
            <div className="text-wrapper-13">Dreadlock</div>
          </div>
          <div className="frame-11">
            <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-238-1.png" />
            <div className="text-wrapper-14">Bob cut</div>
          </div>
          <div className="frame-11">
            <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-238-2.png" />
            <div className="text-wrapper-14">Bob cut</div>
          </div>
          <div className="frame-11">
            <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-238.png" />
            <div className="text-wrapper-13">Dreadlock</div>
          </div>
          <div className="frame-11">
            <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-238-1.png" />
            <div className="text-wrapper-14">Bob cut</div>
          </div>
          <div className="frame-11">
            <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-238-2.png" />
            <div className="text-wrapper-14">Bob cut</div>
          </div>
        </div>
        <div className="text-wrapper-15">Trending hairstyle</div>
      </div>
      <div className="email-subscription">
        <div className="frame-12">
          <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
          <div className="frame-13">
            <div className="title">
              <div className="text-wrapper-16">Subscribe to newsletter</div>
              <p className="text-wrapper-17">
                Sign up for our newsletter to stay up-to-date on the latest promotions, discounts, and new features
                releases.
              </p>
            </div>
            <div className="subscribe">
              <div className="mail">
                <div className="text-wrapper-18">Enter your mail</div>
                <img className="email" alt="Email" src="/img/email-1.svg" />
              </div>
              <button className="subscribe-btn">
                <div className="text-wrapper-19">Subscribe</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="group-17">
        <div className="overlap-6">
          <p className="element-birch-arden">© 2024 Birch Arden &amp; Nubia. All rights reserved.</p>
          <p className="birch-arden-nubia-is">
            Birch Arden &amp; Nubia is your trusted
            <br />
            beauty service provider. We
            <br />
            make it simple and secure for
            <br />
            you to book and plan your look.
          </p>
          <div className="text-wrapper-20">Explore</div>
          <div className="text-wrapper-21">Utility Page</div>
          <div className="group-18">
            <div className="text-wrapper-22">Follow us</div>
            <div className="group-19">
              <a
                className="group-20"
                href="https://twitter.com/recharge_profit"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="footer-button">
                  <div className="home">X</div>
                </div>
                <img className="img-2" alt="X" src="/img/x-g2y.svg" />
              </a>
              <a
                className="group-21"
                href="https://www.instagram.com/recharge_profit"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="footer-button">
                  <div className="home">Instagram</div>
                </div>
                <img className="img-2" alt="Insta" src="/img/insta-g2y.svg" />
              </a>
              <a
                className="group-22"
                href="https://www.facebook.com/recharge_profit"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="footer-button">
                  <div className="home">Facebook</div>
                </div>
                <img className="img-2" alt="Fb" src="/img/fb-g2y.svg" />
              </a>
              <a
                className="group-23"
                href="https://www.youtube.com/channel/UCmU5KDrY3Wdx0vk6A5JNDdw"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="footer-button">
                  <div className="home">YouTube</div>
                </div>
                <div className="YT" />
              </a>
            </div>
          </div>
          <div className="text-wrapper-23">Home</div>
          <div className="text-wrapper-24">Privacy Policy</div>
          <div className="text-wrapper-25">Terms And Condition</div>
          <div className="text-wrapper-26">About Us</div>
          <div className="text-wrapper-27">Services</div>
          <div className="text-wrapper-28">Contact Us</div>
          <img className="line" alt="Line" src="/img/line-40.svg" />
        </div>
      </div>
      <div className="overlap-7">
        <div className="group-24">
          <div className="engage-a-personal-wrapper">
            <p className="engage-a-personal">
              <span className="span">Engage </span>
              <span className="text-wrapper-29">A</span>
              <span className="span">
                {" "}
                Personal <br />
              </span>
              <span className="text-wrapper-29">Stylist!</span>
            </p>
          </div>
          <div className="group-25">
            <div className="text-wrapper-30">Products!</div>
          </div>
          <div className="match-or-craft-wrapper">
            <div className="match-or-craft">
              Match or
              <br />
              Craft!
            </div>
          </div>
          <div className="frame-wrapper">
            <div className="frame-14">
              <div className="style-redefined-just-wrapper">
                <p className="style-redefined-just">
                  <span className="text-wrapper-31">Style</span>
                  <span className="text-wrapper-32">
                    {" "}
                    Redefined, <br />
                    Just{" "}
                  </span>
                  <span className="text-wrapper-31">for</span>
                  <span className="text-wrapper-32"> You</span>
                </p>
              </div>
              <button className="button-2">
                <div className="rectangle-10" />
                <div className="get-in-touch">Book Now</div>
              </button>
            </div>
          </div>
        </div>
        <img className="vuesax-linear" alt="Vuesax linear" src="/img/vuesax-linear-textalign-justifycenter-1.svg" />
      </div>
      <div className="group-26">
        <img className="frame-15" alt="Frame" src="/img/image.svg" />
        <img className="slice-2" alt="Slice" src="/img/slice-1-2.png" />
      </div>
    </div>
  );
};
