import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MacbookPro } from "./screens/MacbookPro";
import { MacbookProScreen } from "./screens/MacbookProScreen";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <MacbookPro />,
  },
  {
    path: "/macbook-pro-14u34-12",
    element: <MacbookPro />,
  },
  {
    path: "/macbook-pro-14u34-10",
    element: <MacbookProScreen />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
